import React, {useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './TravelHabitsForm.css';
import {BinData} from "./types";
import {SbbFormField} from "@sbb-esta/lyne-react/form-field";
import {SbbSelect} from "@sbb-esta/lyne-react/select";
import {SbbOption} from "@sbb-esta/lyne-react/option";
import {SbbContainer} from "@sbb-esta/lyne-react/container";
import {SbbButton, SbbSecondaryButton, SbbTertiaryButton} from "@sbb-esta/lyne-react/button";
import {SbbActionGroup} from "@sbb-esta/lyne-react/action-group";
import {SbbDatepickerToggle} from "@sbb-esta/lyne-react/datepicker";
import {SbbCheckbox} from "@sbb-esta/lyne-react/checkbox";
import {useTranslation} from "react-i18next";

export interface UserInputFormProps {
    setBinData: (data: (prevBinData: BinData[]) => BinData[]) => void;
}

export interface TravelHabit {
    startLocation: string;
    endLocation: string;
    startDate: string;
    endDate: string;
    fullPricePerJourney: string;
    secondClassFullPrice?: string; // Add second class price field
    frequencyPerWeek: string;
    travelClass: string;
}

export interface TravelHabitRequest {
    travelHabits: {
        startLocation: string;
        endLocation: string;
        startDate: string;
        endDate: string;
        second_class_full_price: string;
        first_class_full_price?: string;
        frequencyPerWeek: string;
    }[];
    config: { alleleType: string, customerCategory: string, isDisabledPerson: boolean };
}

// Define the customer categories (RunConfigCategories)
const customerCategories = [
    "UNDER_STRICT_16",
    "UNDER_STRICT_25",
    "25",
    "OVER_25",
    "OVER_64_WOMAN",
    "OVER_65_MAN"
];

// Fixed color map for allele types
const alleleTypeColors: { [key: string]: string } = {
    "ALL": "#4CAF50",        // Green
    "HTA_PLUS": "#2196F3",   // Blue
    "GA": "#FFC107",         // Amber
    "HTA": "#FF5722",        // Deep Orange
};

const TravelHabitsForm: React.FC<UserInputFormProps> = ({setBinData}) => {
    const {t} = useTranslation();
    const today = new Date();
    const oneYearFromNow = new Date(today);
    oneYearFromNow.setFullYear(today.getFullYear() + 1);
    oneYearFromNow.setDate(-1);

    const formatDate = (date: Date) => date.toISOString().split('T')[0]; // Format as YYYY-MM-DD

    const [travelHabits, setTravelHabits] = useState<TravelHabit[]>([
        {
            startLocation: 'Zürich',
            endLocation: 'Bern',
            startDate: formatDate(today),
            endDate: formatDate(oneYearFromNow),
            fullPricePerJourney: '106',
            frequencyPerWeek: '2.5',
            travelClass: '2nd class',
        },
    ]);

    // States for customerCategory and isDisabledPerson
    const [customerCategory, setCustomerCategory] = useState<string>(customerCategories[3]);
    const [isDisabledPerson, setIsDisabledPerson] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        const updatedHabits = [...travelHabits];
        updatedHabits[index][name as keyof TravelHabit] = value;
        setTravelHabits(updatedHabits);
    };

    const removeTravelHabit = () => {
        if (travelHabits.length > 1) {
            setTravelHabits(travelHabits.slice(0, -1)); // Removes the last travel habit
        }
    };

    const addTravelHabit = () => {
        const lastHabit = travelHabits[travelHabits.length - 1];
        const lastEndDate = new Date(lastHabit.endDate);
        const nextStartDate = new Date(lastEndDate);
        nextStartDate.setDate(lastEndDate.getDate() + 1);
        const nextEndDate = new Date(nextStartDate);
        nextEndDate.setFullYear(nextStartDate.getFullYear() + 1);
        nextEndDate.setDate(-1);

        setTravelHabits([
            ...travelHabits,
            {
                startLocation: 'Zürich',
                endLocation: 'Basel',
                startDate: formatDate(nextStartDate),
                endDate: formatDate(nextEndDate),
                fullPricePerJourney: '72',
                frequencyPerWeek: '1',
                travelClass: '2nd class',
            },
        ]);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (isSubmitting) return;

        setBinData(() => []); // Clear bin data
        setIsSubmitting(true);

        try {
            const allele_all = (travelHabits.filter(travelHabit => travelHabit.travelClass === "1st class").length > 0) ? "ALL_1st_as_well" : "ALL";
            const allele_ga = (travelHabits.filter(travelHabit => travelHabit.travelClass === "1st class").length > 0) ? "GA_1st_as_well" : "GA";

            const requests = [
                sendPostRequestAndPoll({alleleType: allele_all}),
                sendPostRequestAndPoll({alleleType: "HTA_PLUS"}),
                sendPostRequestAndPoll({alleleType: allele_ga}),
                sendPostRequestAndPoll({alleleType: "HTA"}),
            ];
            await Promise.all(requests);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred.';
            toast.error(`Error: ${errorMessage}`, {
                position: "top-right",
                autoClose: 120000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const sendPostRequestAndPoll = async (config: { alleleType: string }) => {
        const travelHabitsPayload = travelHabits.map(habit => {
            // Create the payload without fullPricePerJourney
            const basePayload = {
                startLocation: habit.startLocation,
                endLocation: habit.endLocation,
                startDate: habit.startDate,
                endDate: habit.endDate,
                frequencyPerWeek: habit.frequencyPerWeek,
                travelClass: habit.travelClass === "2nd class" ? "SECOND_CLASS" : "FIRST_CLASS",
            };

            if (habit.travelClass === '2nd class') {
                // If 2nd class, include second_class_full_price only
                return {
                    ...basePayload,
                    second_class_full_price: habit.fullPricePerJourney, // Use fullPricePerJourney as second_class_full_price
                };
            } else {
                // If 1st class, include both first_class_full_price and second_class_full_price
                return {
                    ...basePayload,
                    second_class_full_price: habit.secondClassFullPrice || '', // Use the new second class field
                    first_class_full_price: habit.fullPricePerJourney, // fullPricePerJourney becomes first_class_full_price
                };
            }
        });

        const requestBody: TravelHabitRequest = {
            travelHabits: travelHabitsPayload,
            config: {...config, customerCategory, isDisabledPerson}
        };

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/journeyHabits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error('Failed to start the job.');
        }

        const result = await response.json();
        await pollJobStatus(result.id, config.alleleType);
    };

    const pollJobStatus = async (jobId: string, alleleType: string) => {
        let attempts = 0;
        const maxAttempts = 360;
        return new Promise<void>((resolve, reject) => {
            const intervalId = setInterval(async () => {
                attempts++;
                if (attempts > maxAttempts) {
                    clearInterval(intervalId);
                    toast.error('Job took too long to complete.');
                    reject(new Error('Job took too long to complete.'));
                    return;
                }

                try {
                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/journeyHabits/status/${jobId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch job status.');
                    }

                    const job = await response.json();

                    if (job.status === 'completed') {
                        clearInterval(intervalId);

                        if (job.result && job.result.bin) {
                            const firstBin: BinData = {
                                label: job.result.bin.label,
                                value: job.result.bin.value,
                                color: alleleTypeColors[alleleType],
                                isBest: false,
                                computationalDetails: {
                                    list_of_abos: job.result.bin.computationalDetails.list_of_abos,
                                    half_fare_card_details: {
                                        hta_abo_periods: job.result.bin.computationalDetails.half_fare_card_details.hta_abo_periods,
                                        cost: Number(job.result.bin.computationalDetails.half_fare_card_details.cost)
                                    },
                                    overview: job.result.bin.computationalDetails.overview,
                                    user_input: job.result.bin.computationalDetails.user_input
                                }
                            };

                            setBinData((prevBinData: BinData[]) => {
                                const updatedBinData = [...prevBinData, firstBin];
                                const minValue = Math.min(...updatedBinData.map(bin => bin.value));
                                return updatedBinData.map(bin => ({
                                    ...bin,
                                    isBest: bin.value === minValue
                                })) as BinData[];
                                ;
                            });
                        } else {
                            toast.warn('No data returned from the server.');
                        }
                        resolve();
                    } else if (job.status === 'failed') {
                        clearInterval(intervalId);
                        toast.error('Job failed to complete.');
                        reject(new Error('Job failed to complete.'));
                    }
                } catch (error) {
                    console.error('Error polling job status:', error);
                    clearInterval(intervalId);
                    toast.error('Error fetching job status.');
                    reject(error);
                }
            }, 2000);
        });
    };

    return (
        <SbbContainer>
            <form onSubmit={handleSubmit}>
                {/* Config: Customer Category and Is Disabled Person */}
                <fieldset>
                    <legend>{t('form.configuration')}</legend>

                    {/* Customer Category */}
                    <SbbFormField error-space="none" size="m" width="default" borderless>
                        <label htmlFor="customerCategory">{t('form.customerCategory')}</label>
                        <SbbSelect
                            id="customerCategory"
                            value={customerCategory}
                            onChange={(e) => {
                                const customEvent = e as unknown as CustomEvent<{ value: string }>;
                                const selectedValue = customEvent.detail?.value || (e.target as HTMLSelectElement).value;
                                setCustomerCategory(selectedValue);
                            }}
                            required
                        >
                            {customerCategories.map((category) => (
                                <SbbOption key={category} value={category}>
                                    {t(`customerCategories.${category}`)} {/* Translate each category */}
                                </SbbOption>
                            ))}
                        </SbbSelect>
                    </SbbFormField>

                    {/* Is Disabled Person */}
                    <SbbFormField error-space="none" size="m" width="default" borderless>
                        <label htmlFor="isDisabledPerson">{t('form.disabledPerson')}</label>
                        <SbbCheckbox
                            id="isDisabledPerson"
                            checked={isDisabledPerson}
                            onChange={(e) => {
                                const checkbox = e.target as HTMLInputElement;
                                setIsDisabledPerson(checkbox.checked);
                            }}
                        >
                        </SbbCheckbox>
                    </SbbFormField>

                </fieldset>

                {/* Travel Habits */}
                {travelHabits.map((habit, index) => (
                    <fieldset key={index}>
                        <legend>{t('form.travelHabit', {index: index + 1})}</legend>
                        <SbbFormField error-space="none" size="m" width="default" borderless>
                            <label htmlFor={`startLocation-${index}`}>{t('form.startLocation')}</label>
                            <input
                                id={`startLocation-${index}`}
                                type="text"
                                name="startLocation"
                                placeholder={t('form.startLocationPlaceholder')}
                                value={habit.startLocation}
                                onChange={(event) => handleChange(index, event)}
                                required
                            />
                        </SbbFormField>
                        <SbbFormField error-space="none" size="m" width="default" borderless>
                            <label htmlFor={`endLocation-${index}`}>{t('form.endLocation')}</label>
                            <input
                                id={`endLocation-${index}`}
                                type="text"
                                name="endLocation"
                                placeholder={t('form.endLocationPlaceholder')}
                                value={habit.endLocation}
                                onChange={(event) => handleChange(index, event)}
                                required
                            />
                        </SbbFormField>
                        <SbbFormField error-space="none" size="m" width="default" borderless>
                            <label htmlFor={`startDate-${index}`}>{t('form.startDate')}</label>
                            <input
                                id={`startDate-${index}`}
                                type="date"
                                name="startDate"
                                value={habit.startDate}
                                onChange={(event) => handleChange(index, event)}
                                required
                            />
                            <SbbDatepickerToggle></SbbDatepickerToggle>
                        </SbbFormField>
                        <SbbFormField error-space="none" size="m" width="default" borderless>
                            <label htmlFor={`endDate-${index}`}>{t('form.endDate')}</label>
                            <input
                                id={`endDate-${index}`}
                                type="date"
                                name="endDate"
                                value={habit.endDate}
                                onChange={(event) => handleChange(index, event)}
                                required
                            />
                            <SbbDatepickerToggle></SbbDatepickerToggle>
                        </SbbFormField>
                        <SbbFormField error-space="none" size="m" width="default" borderless>
                            <label htmlFor={`fullPricePerJourney-${index}`}>{t('form.fullPricePerJourney')}</label>
                            <input
                                id={`fullPricePerJourney-${index}`}
                                type="number"
                                name="fullPricePerJourney"
                                value={habit.fullPricePerJourney}
                                placeholder={t('form.fullPricePerJourneyPlaceholder')}
                                onChange={(event) => handleChange(index, event)}
                                step="0.01"
                                min="0"
                                required
                            />
                        </SbbFormField>

                        {/* Show second-class price input when 1st class is selected */}
                        {habit.travelClass === '1st class' && (
                            <SbbFormField error-space="none" size="m" width="default" borderless>
                                <label
                                    htmlFor={`secondClassFullPrice-${index}`}>{t('form.secondClassFullPrice')}</label>
                                <input
                                    id={`secondClassFullPrice-${index}`}
                                    type="number"
                                    name="secondClassFullPrice"
                                    placeholder={t('form.secondClassPricePlaceholder')}
                                    value={habit.secondClassFullPrice || ''}
                                    onChange={(event) => handleChange(index, event)}
                                    step="0.01"
                                    min="0"
                                    required
                                />
                            </SbbFormField>
                        )}

                        <SbbFormField error-space="none" size="m" width="default" borderless>
                            <label htmlFor={`frequencyPerWeek-${index}`}>{t('form.frequencyPerWeek')}</label>
                            <input
                                id={`frequencyPerWeek-${index}`}
                                type="number"
                                name="frequencyPerWeek"
                                placeholder={t('form.frequencyPerWeekPlaceholder')}
                                value={habit.frequencyPerWeek}
                                onChange={(event) => handleChange(index, event)}
                                required
                            />
                        </SbbFormField>
                        <SbbFormField error-space="none" size="m" width="default" borderless>
                            <label htmlFor={`travelClass-${index}`}>{t('form.travelClass')}</label>
                            <SbbSelect
                                id={`travelClass-${index}`}
                                value={habit.travelClass}
                                onChange={(event) => {
                                    let selectedValue: string | null = null;

                                    // Safely cast the event to 'CustomEvent' by converting it to 'unknown' first
                                    const customEvent = event as unknown as CustomEvent<{ value: string }>;

                                    // Check if it's a CustomEvent with a 'detail' containing 'value'
                                    if (customEvent?.detail?.value) {
                                        selectedValue = customEvent.detail.value;
                                    } else if (event.target) {
                                        // Otherwise, treat it as a regular ChangeEvent
                                        selectedValue = (event.target as HTMLSelectElement).value;
                                    }

                                    // If we have a valid value, proceed with handleChange
                                    if (selectedValue !== null) {
                                        handleChange(index, {
                                            target: {
                                                name: 'travelClass',
                                                value: selectedValue
                                            }
                                        } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                                    }
                                }}
                            >
                                <SbbOption value="2nd class">{t('form.secondClass')}</SbbOption>
                                <SbbOption value="1st class">{t('form.firstClass')}</SbbOption>
                            </SbbSelect>
                        </SbbFormField>
                    </fieldset>
                ))}

                <SbbActionGroup align-group="start" orientation="horizontal" horizontal-from="unset" button-size="s">
                    <SbbSecondaryButton onClick={addTravelHabit}>
                        {t('form.addTravelHabit')}
                    </SbbSecondaryButton>
                    {travelHabits.length > 1 && (
                        <SbbTertiaryButton onClick={removeTravelHabit}>
                            {t('form.removeTravelHabit')}
                        </SbbTertiaryButton>
                    )}
                    <SbbButton type="submit" disabled={isSubmitting}>
                        {isSubmitting ? t('form.submitting') : t('form.submit')}
                    </SbbButton>
                </SbbActionGroup>

                <ToastContainer/>
            </form>
        </SbbContainer>
    );
};

export default TravelHabitsForm;
